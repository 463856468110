var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-collapse',{attrs:{"id":"modal-menu"},model:{value:(_vm.alternativeAccordions['main-modal-menu']),callback:function ($$v) {_vm.$set(_vm.alternativeAccordions, 'main-modal-menu', $$v)},expression:"alternativeAccordions['main-modal-menu']"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"flex-grow-1"}),_c('div',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.modal-menu",modifiers:{"modal-menu":true}}],attrs:{"variant":"light"}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("clear")])])],1)]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"scroller"},[_c('div',{staticClass:"items"},[_vm._l((_vm.items),function(menu,index){return [(
                  (menu.permissions === 'whitelabel' &&
                    _vm.$store.state.admin.whitelabel.account_id ===
                      _vm.$store.state.account.id) ||
                  (menu.scope === 'whatsapp/jv' &&
                    (_vm.whitelabelId === null || _vm.$live.isMeta)) ||
                  (menu.scope === 'whatsapp' &&
                    (_vm.whitelabelId === null || _vm.available.whatsapp)) ||
                  ((menu.scope === 'live' || menu.scope === 'flow') &&
                    _vm.$live.acceptConnect()) ||
                  (menu.permissions !== 'yupchat' &&
                    menu.permissions !== 'whitelabel' &&
                    menu.scope !== 'whatsapp/jv' &&
                    menu.scope !== 'whatsapp' &&
                    menu.scope !== 'live' &&
                    menu.scope !== 'flow')
                )?[(menu.subItems)?_c('div',{key:index},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`modal-menu-accordion-${index}`),expression:"`modal-menu-accordion-${index}`"}],staticClass:"menu btn-light",class:{ active: _vm.activeMainMenu(_vm.currentRoute, menu) },attrs:{"variant":"light"}},[(menu.embed)?_c('i',{domProps:{"innerHTML":_vm._s(menu.embed)}}):(menu.icon)?_c('i',{class:`icon-font ${menu.icon}`}):(menu.icon)?_c('i',{staticClass:"icon-font ${menu.icon}`"}):_vm._e(),_c('i',{staticClass:"show-sub"},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("navigate_next")])]),_c('div',{staticClass:"sub-title"},[_vm._v(_vm._s(menu.title))])]),_c('b-collapse',{staticClass:"modal-submenu",attrs:{"role":"tabpanel","accordion":"modal-menu-accordion","id":`modal-menu-accordion-${index}`},model:{value:(_vm.alternativeAccordions[index]),callback:function ($$v) {_vm.$set(_vm.alternativeAccordions, index, $$v)},expression:"alternativeAccordions[index]"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header align-items-center"},[_c('div',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`modal-menu-accordion-${index}`),expression:"`modal-menu-accordion-${index}`"}],attrs:{"variant":"light"}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("chevron_left")])])],1),_c('div',{staticClass:"flex-grow-1"},[_vm._v(_vm._s(menu.title))])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"scroller"},[_c('div',{staticClass:"items"},[_vm._l((menu.subItems),function(subMenu,jindex){return [(
                                  subMenu.permissions === 'yupchat'
                                    ? _vm.whitelabelId === null
                                    : true
                                )?[_c('div',{key:jindex},[(subMenu.link)?_c('router-link',{staticClass:"menu btn btn-light",class:{
                                      active: subMenu.link === _vm.currentRoute,
                                    },attrs:{"to":subMenu.link},nativeOn:{"click":function($event){return _vm.closeModalMenus()}}},[(subMenu.icon)?_c('i',{class:`icon-font ${subMenu.icon}`}):(subMenu.embed)?_c('i',{staticClass:"svg",domProps:{"innerHTML":_vm._s(subMenu.embed)}}):_vm._e(),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(subMenu.title))]),_c('div',{staticClass:"sub-title"},[_vm._v(" "+_vm._s(subMenu.title)+" ")])]):_c('button',{staticClass:"menu btn btn-light",on:{"click":function($event){return _vm.closeModalMenus()}}},[(subMenu.icon)?_c('i',{class:`icon-font ${subMenu.icon}`}):(subMenu.embed)?_c('i',{staticClass:"svg",domProps:{"innerHTML":_vm._s(subMenu.embed)}}):_vm._e(),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(subMenu.title))])])],1)]:_vm._e()]})],2)])])])])],1):_c('div',{key:index},[(menu.link)?_c('router-link',{staticClass:"menu btn btn-light",class:{ active: menu.link === _vm.currentRoute },attrs:{"to":menu.link},nativeOn:{"click":function($event){return _vm.closeModalMenus()}}},[(menu.icon)?_c('i',{class:`icon-font ${menu.icon}`}):(menu.embed)?_c('i',{staticClass:"svg",domProps:{"innerHTML":_vm._s(menu.embed)}}):_vm._e(),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(menu.title))]),_c('div',{staticClass:"sub-title"},[_vm._v(_vm._s(menu.title))])]):_c('button',{staticClass:"menu btn btn-light",on:{"click":function($event){return _vm.closeModalMenus()}}},[(menu.icon)?_c('i',{class:`icon-font ${menu.icon}`}):(menu.embed)?_c('i',{staticClass:"svg",domProps:{"innerHTML":_vm._s(menu.embed)}}):_vm._e(),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(menu.title))]),_c('div',{staticClass:"sub-title"},[_vm._v(_vm._s(menu.title))])])],1)]:_vm._e()]})],2)])])])]),_c('div',{attrs:{"id":"sidebar"}},[_c('scrollbar',{ref:"sidebarScroll",staticClass:"accordion",class:{ hasAccordion: _vm.hasAccordion },attrs:{"role":"tablist"}},[_vm._l((_vm.items),function(menu,index){return [(
            (menu.permissions === 'whitelabel' &&
              _vm.$store.state.admin.whitelabel.account_id ===
                _vm.$store.state.account.id) ||
            (menu.scope === 'whatsapp/jv' &&
              (_vm.whitelabelId === null || _vm.$live.isMeta)) ||
            (menu.scope === 'whatsapp' &&
              (_vm.whitelabelId === null || _vm.available.whatsapp)) ||
            ((menu.scope === 'live' || menu.scope === 'flow'  || menu.scope === 'pipelines') &&
              _vm.$live.acceptConnect()) ||
            (menu.permissions !== 'yupchat' &&
              menu.permissions !== 'whitelabel' &&
              menu.scope !== 'whatsapp/jv' &&
              menu.scope !== 'whatsapp' &&
              menu.scope !== 'live' &&
              menu.scope !== 'flow' &&
              menu.scope !== 'pipelines')
          )?[(menu.subItems)?_c('div',{key:index},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`sidebar-accordion-${index}`),expression:"`sidebar-accordion-${index}`"},{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:({ customClass: 'primary-tooltip' }),expression:"{ customClass: 'primary-tooltip' }",modifiers:{"hover":true,"right":true}}],staticClass:"menu",class:{ active: _vm.activeMainMenu(_vm.currentRoute, menu) },attrs:{"variant":"light","title":menu.title}},[(menu.embed)?_c('i',{domProps:{"innerHTML":_vm._s(menu.embed)}}):(menu.icon)?_c('i',{class:`icon-font ${menu.icon}`}):_vm._e(),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(menu.title))]),(menu.scope === 'live' && _vm.account.is_agent)?[_c('audio',{ref:"soundNotification",refInFor:true,staticClass:"sr-only",attrs:{"controls":"","id":"soundNotification","muted":_vm.$live.volume <= 0}},[_c('source',{attrs:{"src":require("../../assets/sounds/yupchat-notification.ogg"),"type":"audio/ogg; codecs=vorbis"}}),_c('source',{attrs:{"src":require("../../assets/sounds/yupchat-notification.mp3"),"type":"audio/mpeg"}})]),(_vm.account.is_agent)?_c('div',{class:{
                    'omni-pushs': true,
                    'omni-notifications': _vm.$live.hasNotifications,
                    'bg-success': _vm.$live.status === 'AVAILABLE',
                    'bg-warning': _vm.$live.status === 'UNAVAILABLE',
                    'bg-info': _vm.$live.status === 'BREAK',
                    'bg-danger': _vm.$live.status === 'OFFLINE',
                  }}):_vm._e()]:_vm._e()],2),_c('b-collapse',{attrs:{"role":"tabpanel","accordion":"sidebar-accordion","id":`sidebar-accordion-${index}`},model:{value:(_vm.accordions[index]),callback:function ($$v) {_vm.$set(_vm.accordions, index, $$v)},expression:"accordions[index]"}},[_vm._l((menu.subItems),function(subMenu,jindex){return [(
                    subMenu.permissions === 'yupchat'
                      ? _vm.whitelabelId === null
                      : true
                  )?[(subMenu.link)?_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:({
                      customClass: 'primary-tooltip',
                    }),expression:"{\n                      customClass: 'primary-tooltip',\n                    }",modifiers:{"hover":true,"right":true}}],key:jindex,staticClass:"menu btn",class:{ active: subMenu.link === _vm.currentRoute },attrs:{"to":subMenu.link,"title":subMenu.title}},[(subMenu.icon)?_c('i',{class:`icon-font ${subMenu.icon}`}):(subMenu.embed)?_c('i',{staticClass:"svg",domProps:{"innerHTML":_vm._s(subMenu.embed)}}):_vm._e(),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(subMenu.title))])]):_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:({
                      customClass: 'primary-tooltip',
                    }),expression:"{\n                      customClass: 'primary-tooltip',\n                    }",modifiers:{"hover":true,"right":true}}],key:jindex,staticClass:"menu btn btn-light",attrs:{"title":subMenu.title}},[(subMenu.icon)?_c('i',{class:`icon-font ${subMenu.icon}`}):(subMenu.embed)?_c('i',{staticClass:"svg",domProps:{"innerHTML":_vm._s(subMenu.embed)}}):_vm._e(),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(subMenu.title))])])]:_vm._e()]})],2)],1):(menu.link)?_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:({ customClass: 'primary-tooltip' }),expression:"{ customClass: 'primary-tooltip' }",modifiers:{"hover":true,"right":true}}],key:index,staticClass:"menu btn",class:{ active: menu.link === _vm.currentRoute },attrs:{"to":menu.link,"title":menu.title}},[(menu.icon)?_c('i',{class:`icon-font ${menu.icon}`}):(menu.embed)?_c('i',{staticClass:"svg",domProps:{"innerHTML":_vm._s(menu.embed)}}):_vm._e(),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(menu.title))])]):_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:({ customClass: 'primary-tooltip' }),expression:"{ customClass: 'primary-tooltip' }",modifiers:{"hover":true,"right":true}}],key:index,staticClass:"menu btn btn-light",attrs:{"title":menu.title}},[(menu.icon)?_c('i',{class:`icon-font ${menu.icon}`}):(menu.embed)?_c('i',{staticClass:"svg",domProps:{"innerHTML":_vm._s(menu.embed)}}):_vm._e(),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(menu.title))])])]:_vm._e()]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }